import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0px;

  @media ${theme.device.laptop} {
    margin: 0px;
    position: relative;
  }
`;

export const StyledTitle = styled.h2`
  color: ${theme.colors.dark};
  font-size: ${theme.typography.fontSize.l};
  font-weight: ${theme.typography.fontWeight.extraBold};
  line-height: 40px;

  @media ${theme.device.laptop} {
    font-size: ${theme.typography.fontSize.r};
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-end;

  @media ${theme.device.laptop} {
    flex-direction: column;
    gap: 50px;
  }
`;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, useContext } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { URLs, templateId } from 'src/constants';
import { PopUpEnum } from 'types/common';

import CalculatorStore from '../CalculatorStore';

class GlobalStore {
  constructor() {
    makeObservable(this, {
      isLoading: observable,
      showPreloader: action,
      hidePreloader: action,
      submitLoading: observable,
      setSubmitLoading: action,
      //success send report
      isSuccess: observable,
      setIsSuccess: action,
      //errors
      errorMessage: observable,
      setError: action,
      //popup
      popUp: observable,
      setPopUpAction: action,
    });

    this.calculatorStore = new CalculatorStore();
    this.isLoading = false;
    this.submitLoading = false;
    this.errorMessage = '';
    this.isSuccess = false;
    this.popUp = null;
  }

  calculatorStore: CalculatorStore;
  isLoading: boolean;
  submitLoading: boolean;
  errorMessage: string;
  isSuccess: boolean;
  popUp: PopUpEnum | null;

  hidePreloader = () => {
    this.isLoading = false;
  };

  showPreloader = () => {
    this.isLoading = true;
    setTimeout(this.hidePreloader, 1500);
  };

  setError = (value: string) => {
    this.errorMessage = value;
  };

  setIsSuccess = (value: boolean) => {
    this.isSuccess = value;
  };

  setPopUpAction = (value: PopUpEnum | null) => {
    this.popUp = value;
  };

  setSubmitLoading = (flag: boolean) => {
    this.submitLoading = flag;
  };

  sendGTagAnalytics = ({ name, email }: { name: string; email: string }) => {
    const GTAG_API_URL = 'https://hook.eu1.make.com/k1713qnwnc47vp9ivt7d5rtjct8ulrxh';
    // @ts-ignore
    if (window?.ga) {
      // @ts-ignore
      window?.ga(tracker => {
        const clientId = tracker.get('clientId');
        fetch(GTAG_API_URL, {
          method: 'POST',
          body: JSON.stringify({
            name,
            email,
            tags: 'calculator',
            ga_client_id: clientId,
          }),
        });
      });
    }
  };

  sendReport = async (data: object) => {
    this.setSubmitLoading(true);
    fetch(URLs.SEND_REPORTS, {
      method: 'POST',
      body: JSON.stringify({ templateId, ...data }),
    })
      .then(response => {
        if (!response.ok) {
          this.setError(`${response.statusText} status: ${response.status}`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
      })
      .then(() => {
        // ADD gtag ANALYTICS
        this.sendGTagAnalytics(data as { name: string; email: string });

        this.setSubmitLoading(false);
        this.setIsSuccess(true);
      })
      .catch(error => {
        this.setSubmitLoading(false);
        this.setError(error.message);
      });
  };
}

export const StoreContext = createContext<GlobalStore | null>(null);

export const useGlobalStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('You have forgot to use StoreProvider, shame on you. (c) Michel Weststrate');
  }
  return store;
};

export default GlobalStore;

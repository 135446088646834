import React, { useCallback, useState } from 'react';
import { getAverageEstimate } from 'utils';
import { GroupedEstimate, PlatformType, QuestionEstimate } from 'types/store';
import { Size } from 'types/common';

import { Estimate } from 'atoms';
import { ContactUs, Tabs } from 'molecules';

import {
  StyledContainer,
  StyledHeader,
  StyledCategoryList,
  StyledCategoryItem,
  StyledQuestionList,
  StyledQuestionItem,
  StyledSubTotal,
  StyledTotal,
  StyledContentWrapper,
} from './PlatfromSummary.styles';
import { PlatfromSummaryProps } from './PlatfromSummary.types';
import { sumQuestionEstimation } from './PlatfromSummary.utils';

const PlatfromSummary: React.FC<PlatfromSummaryProps> = ({
  selectedPlatforms,
  groupedEstimates,
  subtotal,
  total,
  closePopup,
}) => {
  const [activeTab, setActiveTab] = useState(selectedPlatforms[0]);

  const onChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setActiveTab(e.currentTarget.value as PlatformType);
    },
    [setActiveTab],
  );

  const renderQuestion = useCallback(
    (questionEstimate: QuestionEstimate) => {
      const average = getAverageEstimate(
        sumQuestionEstimation(questionEstimate.estimates, activeTab),
      );

      return (
        <StyledQuestionItem key={questionEstimate.id}>
          <span>{questionEstimate.label}</span>
          <span style={{ marginLeft: '20px' }}>{average}h</span>
        </StyledQuestionItem>
      );
    },
    [activeTab],
  );

  const renderCategory = useCallback(
    (groupedEstimate: GroupedEstimate) => {
      return (
        <StyledCategoryItem key={groupedEstimate.id}>
          {groupedEstimate.label}
          <StyledQuestionList>{groupedEstimate.questions.map(renderQuestion)}</StyledQuestionList>
        </StyledCategoryItem>
      );
    },
    [renderQuestion],
  );

  return (
    <StyledContainer>
      <StyledContentWrapper>
        <StyledHeader>Summary</StyledHeader>
      </StyledContentWrapper>
      <Tabs activeTab={activeTab} tabs={selectedPlatforms} onChange={onChange} />
      <StyledContentWrapper>
        <StyledCategoryList>{groupedEstimates.map(renderCategory)}</StyledCategoryList>
        {selectedPlatforms.length > 1 && (
          <StyledSubTotal>
            {activeTab} subtotal
            <Estimate estimate={subtotal[activeTab]} />
          </StyledSubTotal>
        )}
        <StyledTotal>
          total
          <Estimate size={Size.M} estimate={total} />
        </StyledTotal>
      </StyledContentWrapper>
      <ContactUs closePopup={closePopup} />
    </StyledContainer>
  );
};

export default PlatfromSummary;

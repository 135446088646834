import styled, { keyframes } from 'styled-components';
import { theme, customScroll } from 'src/styles/theme';
import { fadeInDown } from 'react-animations';

const fadeAnimation = keyframes`${fadeInDown}`;

export const StyledWrap = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  background-color: white;
  opacity: 1;
  height: 100vh;
  width: 100vw;
  background: ${theme.colors.white};
`;

export const StyledContentWrapper = styled.div`
  margin-top: 60px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  animation: 0.5s ${fadeAnimation};

  ${customScroll}
`;

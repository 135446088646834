import React, { HTMLAttributes, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, platformOptions } from 'src/constants';
import { useGlobalStore } from 'store/GlobalStore';
import { PlatformType } from 'types/store';

import { Title, Description, Button, Container } from 'atoms';
import {
  StyledControls,
  StyledBackButton,
  StyledSelectionList,
  StyledWrapper,
} from './SelectPlatform.styles';
import { useScrollPosition } from 'templates/Layout/Layout.hook';

const SelectPlatform: React.FC<HTMLAttributes<HTMLElement>> = () => {
  const {
    calculatorStore: { domain, platforms, setPlatforms, setGroupedQuestions },
    showPreloader,
  } = useGlobalStore();

  const navigate = useNavigate();

  const [containerClassName] = useScrollPosition();

  const handleOnChange = useCallback(
    values => {
      setPlatforms(values as PlatformType[]);
    },
    [setPlatforms],
  );

  const canMoveNext = Boolean(platforms?.length);

  const handleMoveNext = useCallback(() => {
    setGroupedQuestions();
    showPreloader();
    navigate(AppRoutes.CALCULATOR);
  }, [navigate, showPreloader]);

  const handleMoveBack = useCallback(() => {
    navigate(AppRoutes.SELECT_DOMAIN);
  }, [navigate]);

  useEffect(() => {
    if (!domain) {
      navigate(AppRoutes.SELECT_DOMAIN);
    }
  }, [navigate, domain]);

  return (
    <>
      <Container className={`mainWrapper ${containerClassName}`}>
        <StyledWrapper>
          <Title>What type of platforms do you need?</Title>
          <Description>Select all that applied</Description>
          <StyledSelectionList
            options={platformOptions}
            handleOnChange={handleOnChange}
            preSelectedOptions={platforms}
            multiselect
          />
          <StyledControls>
            <StyledBackButton onClick={handleMoveBack} />
            {canMoveNext && (
              <Button id="nextButton" onClick={handleMoveNext} className="domain">
                Next
              </Button>
            )}
          </StyledControls>
        </StyledWrapper>
      </Container>
    </>
  );
};

export default observer(SelectPlatform);

import { theme } from 'src/styles/theme';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${theme.device.laptop} {
    gap: ${theme.spacing(4)};
    flex-direction: column-reverse;
  }
`;

export const StyledWrapper = styled.div`
  @media ${theme.device.laptop} {
    padding-top: 37px;
  }
`;

export const StyledNotice = styled.span`
  color: ${theme.colors.dark};
  font-size: ${theme.typography.fontSize.s};
  line-height: 24px;
  padding: 15px 0;

  a {
    color: ${theme.colors.dark};
    font-weight: ${theme.typography.fontWeight.semiBold};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${theme.device.laptop} {
    padding: 10px 0 0;
  }
`;
